<div class="conatiner-fluid">
  <div class="row">
    <div class="topBar d-flex">
      <div class="col-6 d-flex justify-content-start mt-3">
        <p>Compare with Average</p>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <button (click)="close('close')" class="btn btn-primay">
          <img
            src="../../../../assets/stateform/Icon feather-menu.svg"
            alt=""
          />
        </button>
      </div>
    </div>
  </div>

  <div [ngSwitch]="type">
    <div *ngSwitchCase="1">
      <!--- revenuechart compare start-->
      <!-- <div class="row p-3 w-60">
        <div class="col-6 infoBar">
          <p class="pt-2">
            <mat-icon style="vertical-align: bottom">info</mat-icon> Information
            about how the comparison works...
          </p>
        </div>
      </div> -->

      <div class="row justify-content-between">
        <div
          *ngFor="let item of filterList; let i = index"
          class="mt-2 inputCheckbox col-md-4 col-sm-6"
        >
          <label>
            <input
              (click)="radioSelected($event)"
              [checked]="item.checked"
              type="radio"
              value="{{ item.val }}"
              name="radioBtn"
              class="checkbox-round"
            />
            {{ item.val }}</label
          >
        </div>
      </div>

      <div class="row mt-2">
        <div class="d-flex">
          <div class="col-5">
            <hr />
          </div>
          <span class="mt-1">OR</span>
          <div class="col-5">
            <hr />
          </div>
        </div>
      </div>
      <p class="topBar d-flex p-3">Compare with maximum 3 ULBs</p>
      <div class="row">
        <div class="col-12 justify-content-around">
          <label>Search ULB</label>
          <div class="d-flex formInput">
            <form class="col-md-9 col-sm-12 p-2 compareInput">
              <input
                matInput
                [formControl]="searchField"
                [matAutocomplete]="auto"
                class="w-100"
                type="text"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <span
                  (click)="optionSelected(option)"
                  *ngFor="let option of filteredOptions; let i = index"
                  style="direction: none"
                >
                  <mat-option
                    [ngClass]="{ firstCls: i == 0 }"
                    [value]="option?.name"
                  >
                    <div class="row">
                      <div class="col-8">
                        <span> {{ option?.name }}</span>
                      </div>
                      <div class="col-4">
                        <span> {{ option?.ulbType.name }}</span>
                      </div>
                    </div>
                  </mat-option>
                </span>

                <mat-option *ngIsf="noDataFound" class="text-danger firstCls">
                  Sorry, we can’t find anything related to your search. Try
                  searching for a city or state
                </mat-option>
              </mat-autocomplete>
            </form>

            <div class="col-md-3 p-2 compareButton">
              <button>Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="2" style="margin-top: 4%" class="alertPara">
      <!-- own revenue dashboard chart compare start -->

      <!-- <span style="color: red" *ngIf="emptyField"
        >Note: Please select atleast two <span *ngIf="togglerValue">ULBs</span>
        <span *ngIf="!togglerValue">States</span> and one parameter for
        comparison
      </span> -->
      <div class="row w-80">
        <div style="line-height: 5rem">
          <mat-label>States </mat-label>
          <mat-slide-toggle [formControl]="toogle"> ULBs</mat-slide-toggle>
        </div>
        <div class="col-12">
          <p class="ownRevText">
            You can select upto 10
            <span *ngIf="togglerValue">ULBs</span>
            <span *ngIf="!togglerValue">States</span> and one parameter at a
            time
          </p>
        </div>
      </div>

      <div class="col-12">
        <div class="col-12 col-md-9 m-2 g-filter-d">
          <form>
            <input
              type="text"
              [placeholder]="placeholder"
              matInput
              [formControl]="globalFormControl"
              [matAutocomplete]="auto"
              class="fl-input w-80"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <a
                (click)="dashboardNav(option, $event)"
                *ngFor="let option of filteredOptions; let i = index"
                style="text-decoration: none"
              >
                <mat-option
                  [ngClass]="{ firstCls: i == 0 }"
                  [value]="option?.name"
                >
                  <div class="row">
                    <div class="col-8">
                      <span> {{ option?.name }}</span>
                    </div>
                    <div *ngIf="togglerValue" class="col-3">
                      <span style="color: #8e8e8e; font-size: small">{{
                        option?.state?.name | titlecase
                      }}</span>
                    </div>
                  </div>
                </mat-option>
              </a>
              <mat-option *ngIf="noDataFound" class="noData firstCls">
                Sorry, we can’t find anything related to your search.
              </mat-option>
              <!--
              <mat-option *ngIf="noDataFound" class="noData firstCls">
                  Sorry, we can’t find anything related to your search. Try searching for a city or state
              </mat-option> -->
            </mat-autocomplete>
          </form>
        </div>
        <div>Select a compare parameter</div>
        <div class="mt-2">
          <mat-form-field class="col-12 col-md-9 col-sm-12 smallScreenDropdown">
            <mat-label>Parameter</mat-label>
            <mat-select #matmat  matInput [formControl]="selectedVal">
              <!-- <mat-option>None</mat-option> -->
              <!-- <div (mouseleave)="matmat.close()"> -->
              <mat-option
                *ngFor="let parameter of parameters"
                [value]="parameter"
                >{{ parameter }}</mat-option
              >
              <!-- </div> -->
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 mb-2">
        <!-- <mat-chip-list> -->
        <mat-chip
          *ngFor="let chips of stateChipList"
          (click)="removeStateChips(chips)"
          class="chipsDiv"
        >
          <!-- {{chips.length > 10 ? chips.slice(0, 10) + "..." : chips}} -->
          {{ chips.name }}

          <button matChipRemove>
            <mat-icon class="cancleIcon">cancel</mat-icon>
          </button>
        </mat-chip>
        <!-- </mat-chip-list> -->
      </div>

      <!--- ownrevenuechart compare start-->
    </div>
    <!-- this is the one being used for state dashboard compare -->
    <div *ngSwitchCase="3">
      <div class="row">
        
        <div *ngIf="balcnceTab" style="color: red; font-style: italic">*Please Select Both ulb and year to filter</div>
      </div>
      <div class="row">
        <div class="col-12 justify-content-around">
          <label class="searchUlb" for="">Search ULB</label>
          <div class="d-flex formInput">
            <form class="col-md-9 col-sm-12 p-2 compareInput">
              <input
                (keyup.enter)="optionSelected(searchField.value)"
                matInput
                [formControl]="searchField"
                [matAutocomplete]="auto"
                style="width: 95%;"
                type="text"
              />
              <mat-autocomplete #auto="matAutocomplete">
                <span
                  (click)="optionSelected(option)"
                  *ngFor="let option of filteredOptions; let i = index"
                  style="direction: none"
                >
                  <mat-option [ngClass]="{ firstCls: i == 0 }" [value]="option">
                    <div class="row">
                      <div class="col-8">
                        <span> {{ option?.name }}</span>
                      </div>
                      <div class="col-4">
                        <span class="subtext"> {{ option?.ulbType.name }}</span>
                      </div>
                    </div>
                  </mat-option>
                </span>

                <mat-option *ngIf="noDataFound" class="text-danger firstCls">
                  Sorry, we can’t find anything related to your search. Try
                  searching for a city or state
                </mat-option>
              </mat-autocomplete>
            </form>

            <div class="col-md-3 p-2 compareButton">
              <button>Search</button>
            </div>
          </div>
        </div>
        <!--- revenuechart compare end-->
      </div>

      <div class="row" *ngIf="showDropDown">
        <div class="yearSelect">
          <mat-form-field appearance="outline" style="width: 70%; margin-left: 2%;">
            <mat-label>Select Years</mat-label>
            <mat-select
              [formControl]="dropYears"
              multiple
              (selectionChange)="selectYearValue($event)"
            >
              <mat-option *ngFor="let year of yearsList" [value]="year.itemName" [disabled]="!year.isDataAvailable"
                >
                <div class="row">
                  <div class="col-8">
                    <span> {{ year.itemName }}</span>
                  </div>
                  <div class="col-4">
                    <span class="subtext"> {{ !year.isDataAvailable ? 'No data available': '' }}</span>
                  </div>
                </div>                
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div> -->

    <!--- revenuechart compare end-->
    <!-- </div> -->
    <!-- </div> -->

    <div class="row">
      <div class="col-12 mb-2">
        <!-- <mat-chip-list> -->
        <mat-chip
          *ngFor="let chips of ulbListChip"
          (click)="remove(chips)"
          class="chipsDiv"
        >
          {{
            chips.name.length > 10
              ? chips.name.slice(0, 10) + "..."
              : chips.name
          }}
          <button matChipRemove>
            <mat-icon class="cancleIcon">cancel</mat-icon>
          </button>
        </mat-chip>
        <!-- </mat-chip-list> -->
      </div>
    </div>

    <div class="row">
      <div class="col-12 justify-content-center m-auto d-flex">
        <div class="lastButtons">
          <button (click)="reset()">Reset</button>
        </div>
        <div class="lastButtons">
          <button
            (click)="emitValues()"
            style="border: 1px solid #e67e15; color: #e67e15"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
